<template>
    <div>
        <!-- 与操作按钮相分隔开的内容 -->
        <div>
        <!-- 基础信息 -->
        <div
        style="
          border: 1px solid #ddd;
          margin-top: 20px;
          padding: 0 20px;
          color: #666;
          font-size: 15px;
          width:100%;
        "
      >
        <div style="font-size: 20px; margin: 8px 0 20px 0">基础信息</div>
        <div class="prCon">
          <div class="childCon">
            <span class="childMt">客户姓名:</span>
            <span>{{ taskDetail.contacts }}</span>
          </div>
          <div class="childCon">
            <span class="childMt">订单状态:</span>
            <span>{{ taskDetail.states }}</span>
          </div>
          <div class="childCon">
            <span class="childMt">订单号:</span>
            <span>{{ taskDetail.order_id }}</span>
          </div>
          <div class="childCon">
            <span class="childMt">店铺:</span>
            <span>{{ taskDetail.store_name }}</span>
          </div>
          <div class="childCon">
            <span class="childMt">负责店长:</span>
            <span>{{ taskDetail.username }}</span>
          </div>
          <div class="childCon">
            <span class="childMt">下单时间:</span>
            <span>{{ taskDetail.created_time }}</span>
          </div>
        </div>
        <div class="prCon">
          <div class="childCon">
            <span class="childMt">联系电话:</span>
            <span>{{ taskDetail.telephone }}</span>
          </div>
          <div class="childCon">
            <span class="childMt">订单来源:</span>
            <span v-if="taskDetail.channelTitle == null || taskDetail.channelTitle == ''">{{ taskDetail.channel }}</span>
            <span v-else>{{ taskDetail.channel }}-{{ taskDetail.channelTitle }}</span>
          </div>
          <div class="childCon">
            <span class="childMt">房屋问题:</span>
            <span>{{ taskDetail.problemTitle }}</span>
          </div>
          <div class="childCon">
            <span class="childMt">添加微信:</span>
            <span v-if="taskDetail.wechat == 1">是</span>
            <span v-else>否</span>
          </div>
          <div class="childCon2">
            <span class="childMt">维修地址:</span>
            <span>{{ taskDetail.addres }}</span>
          </div>
        </div>
        <div class="prCon">
          <div class="childCon3">
            <span class="childMt">备注信息:</span>
            <span>{{ taskDetail.remarks }}</span>
          </div>
        </div>
        </div>
        <!-- 完工回访 -->
        <div
        style="
          border: 1px solid #ddd;
          margin-top: 20px;
          padding: 0 20px;
          color: #666;
          font-size: 15px;
          width:100%;
        "
      >
        <div style="font-size: 20px; margin: 8px 0 20px 0">完工回访</div>
        <div class="prCon">
          <div class="childCon7">
            <span class="childMt">下单时间:</span>
            <span>{{ taskDetail.created_time }}</span>
          </div>
          <div class="childCon7">
            <span class="childMt">派单时间:</span>
            <span>{{ taskDetail.time }}</span>
          </div>
          <div class="childCon7">
            <span class="childMt">打卡时间:</span>
            <span>{{ taskDetail1.clock_in }}</span>
          </div>
          <div class="childCon7">
            <span class="childMt">报价时间:</span>
            <span>{{ taskDetail1.crtime }}</span>
          </div>
          <div class="childCon7">
            <span class="childMt">报价金额:</span>
            <span>{{ taskDetail1.totalprice }}</span>
          </div>
        </div>
        <div class="prCon">
          <div class="childCon7">
            <span class="childMt">开工时间:</span>
            <span>{{ taskDetail1.start_time }}</span>
          </div>
          <div class="childCon7">
            <span class="childMt">预计工期:</span>
            <span>{{ taskDetail1.gong }}天</span>
          </div>
          <div class="childCon7">
            <span class="childMt">完工时间:</span>
            <span>{{ taskDetail1.finish_time }}</span>
          </div>
        </div>
        <!-- <div class="prCon">
          <div class="childCon7">
            <span class="childMt">客户完工评价:</span>
          </div>
          <div class="childCon7">
            <span class="childMt">评价标签:</span>
          </div>
          <div class="childCon8">
            <span class="childMt">评价备注:</span>
          </div>
        </div> -->
        <!-- <div class="prCon">
          <div class="childCon7">
            <span class="childMt">店长服务态度:</span>
          </div>
          <div class="childCon7">
            <span class="childMt">客服服务态度:</span>
          </div>
        </div> -->
        </div>
        <!-- 处理记录 -->
        <div
        style="
          border: 1px solid #ddd;
          margin-top: 20px;
          padding: 0 20px;
          color: #666;
          font-size: 15px;
          width:100%;
          margin-bottom: 10px;
        "
      >
        <div style="font-size: 20px; margin-top: 8px;">处理记录</div>
        <avue-crud :data="dealRecord" :option="option2" >
          <template slot-scope="scope" slot="score">
            <span v-if="scope.row.score == 1">一星级</span>
            <span v-if="scope.row.score == 2">二星级</span>
            <span v-if="scope.row.score == 3">三星级</span>
            <span v-if="scope.row.score == 4">四星级</span>
            <span v-if="scope.row.score == 5">五星级</span>
          </template>
          <template slot-scope="scope" slot="score2">
            <span v-if="scope.row.score2 == 1">一星级</span>
            <span v-if="scope.row.score2 == 2">二星级</span>
            <span v-if="scope.row.score2 == 3">三星级</span>
            <span v-if="scope.row.score2 == 4">四星级</span>
            <span v-if="scope.row.score2 == 5">五星级</span>
          </template>
          <template slot-scope="scope" slot="score3">
            <span v-if="scope.row.score3 == 1">一星级</span>
            <span v-if="scope.row.score3 == 2">二星级</span>
            <span v-if="scope.row.score3 == 3">三星级</span>
            <span v-if="scope.row.score3 == 4">四星级</span>
            <span v-if="scope.row.score3 == 5">五星级</span>
          </template>
        </avue-crud>
        </div>
        <!-- 沟通记录表格 -->
        <div
        style="
          border: 1px solid #ddd;
          margin-top: 20px;
          padding: 0 20px;
          color: #666;
          font-size: 15px;
          width:100%;
          margin-bottom: -50px;
        "
      >
        <div style="font-size: 20px; margin-top: 8px;">沟通记录</div>
        <avue-crud :data="connectRecord" :option="option1" >
          <template slot-scope="scope" slot="conversation">
            <span v-if="scope.row.conversation == 0">是</span>
            <span v-if="scope.row.conversation == 2">否</span>
          </template>
          <template slot-scope="scope" slot="record">
            <div v-if="scope.row.record.length == 0">暂无录音</div>
            <div v-else>
              <audio
                style="width: 100%; outline: none"
                :src="scope.row.record[0].monitorfilename"
                controls="controls"
              ></audio>
            </div>
          </template>
          <template slot="log" slot-scope="scope">
            <span v-if="scope.row.log.length == 0">暂无图片</span>
            <div v-else>
              <img
                v-for="(item, index) in scope.row.log"
                :src="item.value"
                :key="index"
                style="width: 50px; height: 50px; margin-right: 2px"
              />
            </div>
          </template>
        </avue-crud>
        </div>
        </div>
        <!-- 底部操作按钮 -->
      <div style="background: #dae1e6; display: flex; padding: 2% 0 2% 20%;margin:2% 0 0 -3%!important;position: fixed;width:80%;left:274px;bottom:0;z-index:99;">
        <div
          style="
            padding-right: 5%;
            margin-right: 5%;
            border-right: 1px solid white;
          "
        >
          <el-button @click="addWechat" style="color:red;" size="medium" v-if="taskDetail.wechat == 1">加微信标记(已添加)</el-button>
          <el-button type="primary" size="medium" plain @click="addWechat" v-else>加微信标记(未添加)</el-button>
        </div>
        <div>
          <el-button  type="primary" size="medium" plain @click="lookOrigin">查看原始订单</el-button>
        </div>
      </div>
<!-- 查看原始订单 -->
      <el-dialog
        :visible.sync="dialogTableVisibleLook"
        width="70%"
        :close-on-click-modal="false"
      >
        <Detailes2></Detailes2>
      </el-dialog>
    </div>
</template>
<script>
export default {
  props:['parentRow'],
    data(){
        return{
            //任务详细信息
      taskDetail:{
        contacts: "张三",
        state: "一个状态",
        order_id: 202107141422,
        store_name: "金沙店",
        username: "李华",
        created_time: "1994-02-23 00:00:00",
        telephone: 15182996821,
        channel: "推客宝盒",
        problemTitle: "墙面翻新",
        wechat: 1,
        addres: "四川省成都市金牛区万通金融广场西塔",
        remarks: "这是一个备注呢，多写几个字",
      },
      //完工回访
      taskDetail1:{},
      connectRecord: [],
       dealRecord:[],
      option1: {
        size: "medium",
        menu: false,
        page: false,
        align: "left",
        dateBtn: false,
        dateDefault: false,
        addBtn: false,
        delBtn: false,
        editBtn: false,
        columnBtn:false, //列动态显隐按钮
        refreshBtn:false, // 刷新按钮
        column: [
          // {
          //   label: "时间",
          //   prop: "th_time",
          //   type: "date",
          //   format: "yyyy-MM-dd hh:mm:ss",
          //   valueFormat: "yyyy-MM-dd hh:mm:ss",
          // },
          {
            label: "处理人",
            prop: "username",
          },
          {
            label: "方式",
            prop: "mode",
          },
          {
            label: "是否接通",
            prop: "conversation",
            slot:true
          },
          {
            label: "沟通内容",
            prop: "remar",
          },
          {
            label: "沟通录音",
            prop: "record",
            slot:true
          },
          {
            label: "跟进时间",
            prop: "th_time",
            type: "date",
            format: "yyyy-MM-dd hh:mm:ss",
            valueFormat: "yyyy-MM-dd hh:mm:ss",
          },
          {
            label: "下次跟进",
            prop: "end_time",
            type: "date",
            format: "yyyy-MM-dd hh:mm:ss",
            valueFormat: "yyyy-MM-dd hh:mm:ss",
          },
          {
            label: "图片",
            prop: "log",
            type: "upload",
            imgFullscreen: true,
            imgType: "card",
            width: "200px",
            listType: "picture-card",
          },
        ],
      },
      option2: {
        size: "medium",
        menu: false,
        page: false,
        align: "left",
        dateBtn: false,
        dateDefault: false,
        addBtn: false,
        delBtn: false,
        editBtn: false,
        columnBtn: false, //列动态显隐按钮
        refreshBtn: false, // 刷新按钮
        column: [
          {
            label: "时间",
            prop: "uptime",
            width:"200px"
          },
          {
            label: "施工评价星级",
            prop: "score",
            slot:true
          },
          {
            label: "店长评价星级",
            prop: "score2",
            slot:true
          },
          {
            label: "师傅评价星级",
            prop: "score3",
            slot:true
          },
          {
            label: "评价",
            prop: "content",
          },
        ],
      },
      finishDialog1:false,
      radio1:2,
      shopper:"",  
      shoppers:[],
      textarea1:"",
      finishDialog2:false,
      orderTime:"",
      form1: {
        reasonArr: [],
        orderTime: "",
        mode: "",
        reason: "",
        remark: "",
      },
      formLabelWidth:"100px",
      form2:{
        customer:""
      },
      finishDialog3:false,
      finishDialog4:false,
      options:[],
      defaultParams: {
         label: 'title',
         value: 'title',
         children: 'children'
     },
      form:{
        value1:5,
        checkList:[],
        value2:5,
        value3:5,
      },
      formLabelWidth2:"130px",
      clients:[],
      dialogTableVisibleLook:false,
      waihudian: { username: "", mobile: "" },
      wiahulema: true,
      wiahulema2: true,
      waihulun: 0,
      isSaveState: true,
      ExternalData: "",
      call: [],
      //  沟通方式
      modes: [
        {
          value: "电话",
          label: "电话",
        },
        {
          value: "微信",
          label: "微信",
        },
        {
          value: "其他",
          label: "其他",
        },
      ],
        }
    },
    mounted(){
      this.getclient()
      this.getDetail()
    },
    methods:{
      // 获取客服
      getclient() {
        this.util.get(this.HOST + "/Common/CustomerService", {}).then((res) => {
          if (res.code == 200) {
            this.clients = res.data;
          }else{
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset:90
            });
          }
        });
      },
      getDetail(){//获取任务详情
      this.$loading(this.util.opload);
        this.util.get(this.HOST+"/Order/TaskInfo",{
          cc_id:this.parentRow.cc_id,
          order_id:this.parentRow.order_id,
          type:this.parentRow.type,
        }).then((res)=>{
          if(res.code == 200){
            this.taskDetail = res.data
            this.taskDetail1 = res.data.data
            this.connectRecord = res.data.through
            this.dealRecord = res.data.result
            this.$loading().close();
          }else{
            this.$loading().close();
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset:90
            });
          }
        })
        this.managerPhone();
      },
      // 获取店长号码名字
      managerPhone() {
        this.util
        .get(this.HOST + "/User/findShopOwner", {
          order_id: this.$store.state.order_id
        })
        .then(res => {
          if (res.code == 200) {
            this.waihudian = res.data;
          }else{
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset:90
            });
          }
        });
      },
      lookOrigin(){
        this.dialogTableVisibleLook = true
      },
      addWechat(){
        this.$loading(this.util.opload);
       let wechat = ''
        if(this.taskDetail.wechat == 1){
          wechat = 0
        }else{
          wechat = 1
        }
        this.util.get(this.HOST+"/Order/SelectorSwitch",{
          order_id:this.parentRow.order_id,
          wechat:wechat
        }).then((res)=>{
          if(res.code == 200){
            this.$message({
              showClose: true,
              message: "处理成功!",
              type: "success",
              duration: 1200,
              offset:90
            });
          this.getDetail()
        this.$loading().close();
          }else{
            this.$loading().close();
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset:90
            });
          }
        })
        this.getDetail()
      },
      finishCancel(){},
        openFinish(){
      this.finishDialog1 = true
    },
    openSetting(){
      this.finishDialog2 = true
    },
    openDeliver(){
      this.finishDialog3 = true
    },
    recoveryOrder(){//恢复订单
      this.finishDialog4 = true
    },
    handleChange(){//跟进原因选择改变
    },
    rateChange1(){
      this.form.checkList = []
    },
    rateChange2(){
    },
    rateChange3(){
    },
      checkChange(){
      }
    },
    
    components: {
      Detailes2: ()=>import('@/components/detailes2.vue')
    },
}
</script>
<style scoped>
.totalPr {
  background: white !important;
  padding-left: 25px;
  width:96%!important;
}
.container {
  margin: 0 auto;
  background: white;
  padding: 40px 0 0 80px;
  border-radius: 8px;
  /* height: 100%; */
}
.blocks2 {
  margin-right: 20px;
  margin-top: 10px;
}
.abs {
  position: absolute;
}
.wuyekuang {
  top: 37px;
  left: 76px;
  width: 202px;
  list-style: none;
  z-index: 20000;
  overflow: hidden;
  max-height: 143px;
  padding: 6px 0;
  box-sizing: border-box;
  background: white;
  overflow-y: scroll;
  border: 1px solid #e4e7ed;
  color: #666;
}
.zanwu {
  padding: 10px 0;
  margin: 0;
  text-align: center;
  color: #999;
  font-size: 15px;
  top: 50px;
  left: 76px;
  width: 202px;
  box-sizing: border-box;
  background: white;
  border: 1px solid #e4e7ed;
  position: absolute;
  z-index: 999;
  cursor: pointer;
}
.box {
  padding: 30px 0 0 30px;
  background: white;
}
.prCon {
  display: flex;
  flex-wrap: wrap;
}
.childCon {
  width: 16%;
  margin-bottom: 40px;
}
.childCon2 {
  width: 32%;
  margin-bottom: 40px;
}
.childCon3 {
  width: 100%;
  margin-bottom: 20px;
}
.childCon4 {
  width: 24%;
  margin-bottom: 60px;
}
.childCon5 {
  width: 48%;
  margin-bottom: 60px;
}
.childCon6 {
  width: 24%;
  margin-bottom: 20px;
}
.childCon7 {
  width: 20%;
  margin-bottom: 20px;
}
.childCon8 {
  width: 60%;
  margin-bottom: 20px;
}
.childMt {
  margin-right: 8px;
}
.btnStyle {
  border: 1px solid #409eff;
  color: #409eff;
}
.avue-crud__menu{
  min-height: 4px!important;
}
.casStyle{
  width: 300px!important;
}
.checkStyle{
  width:18%;
}
.checkStyle1{
  width:30%;
}
.formItemMb{
  margin-bottom:10px;
}
</style>